import React from "react"
import "./AffiliatesUpdateTop.scss"
import classNames from "classnames"
import bgImage from "./images/bgImage.png"
import bgImageMd from "./images/bgImageMd.png"
import bgImageLg from "./images/bgImageLg.png"
import GoogleStoreButton from "../../common/GoogleStoreButton/GoogleStoreButton"
import AppStoreButton from "../../common/AppStoreButton/AppStoreButton"

function AffiliatesUpdateTop({ type }) {
  const texts = {
    title: "Affiliates Update Allsports+",
    subtitle: "Обновления и изменения услуг AllSports",
  }
  const { title, subtitle } = {...texts}  

  return (
    <section className={classNames("affiliates-top", type)}>      
      <h1 hidden>{title}</h1>
      {subtitle && <h2 className="top-text">{subtitle}</h2>}
      <div className="buttons-wrapper">     
        <AppStoreButton />
        <GoogleStoreButton />
      </div>
      <div className="bg-wrapper">
        <img className="affiliates-top-bg"
          srcSet={
            bgImage +
            " 320w," +
            bgImageMd +
            " 768w," +
            bgImageLg +
            " 1024w," 
          }
          src={bgImage}
          sizes="(min-width: 320px) 320w,
            (min-width: 768px) 768w,
            (min-width: 1024px) 1024w,
            768w"
          alt="background" />
        <div className="bg-circle-large"></div>
        <div className="bg-circle-gray1"></div> 
        <div className="bg-circle-gray2"></div> 
        <div className="bg-circle-gray3"></div> 
        <div className="bg-circle-blue1"></div> 
      </div>
    </section>
  )
}

export default AffiliatesUpdateTop
