import React from "react"
import Button from "../Button/Button.js"
import "./GoogleStoreButton.scss"
import googlePlayBtn from "./googlePlayBtn.png"

const GoogleStoreButton = () => {

  const url = 'https://play.google.com/store/apps/details?id=by.allsports.holder&hl=en_US&gl=US'

  function goToGoogleStore(name) {
    window.open(url, '_blank').focus()
  }

  return (
    <Button color="black" addClass="google-btn" onClick={goToGoogleStore}>
        <img className="google-img" src={googlePlayBtn} alt="google store app" />
    </Button> 
  )
}

export default GoogleStoreButton
