import React from "react"
import Button from "../Button/Button.js"
import "./AppStoreButton.scss"
import appStoreBtn from "./AppStoreBtn.png"

const AppStoreButton = () => {

  const url = 'https://apps.apple.com/ru/app/allsports/id1555419187'

  function goToAppStore(name) {
    window.open(url, '_blank').focus()
  }

  return (
    <Button color="black" addClass="apple-btn" onClick={goToAppStore}>
        <img className="apple-img" src={appStoreBtn} alt="google store app" />
    </Button> 
  )
}

export default AppStoreButton
